import React from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { ReactComponent as Checked } from "../../assets/icons/checked.svg";
import { Button } from "../Button/Button";
import { Separator } from "../Separator/Separator";
import { ReactComponent as Arrow } from "../../assets/icons/arrowline.svg";

import { Badge } from "../Badge/Badge";
import { setId } from "../../store/idSlice";

import { InfoCardProps } from "./InfoCard.types";

import styles from "./InfoCard.module.scss";

export const InfoCard = ({
    title,
    items,
    secondItems,
    variant = "vertical",
    badge,
    hasPointer,
    planId,
    actionButton,
}: InfoCardProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        if (planId === 3) {
            navigate("/contact");
        } else if (planId) {
            dispatch(setId(planId));
            navigate("/register");
        }
    };
    return variant === "vertical" ? (
        <div className={styles.wrapper}>
            {hasPointer && (
                <div className={styles.arrowTextWrapper}>
                    <Arrow />
                    <p className={styles.arrowText}>Najpopularniejsze</p>
                </div>
            )}

            <div className={styles.pointerTextBagdeContainer}>
                {hasPointer && <p className={styles.pointerTextMobile}>Najpopularniejsze</p>}
                <Badge variant={badge.variant}>{badge.text}</Badge>
            </div>
            <p className={styles.title}>{title}</p>
            <ul className={styles.listContainer}>
                {items.map((item, i) => {
                    return (
                        <li key={`firstItems-${i}`} className={styles.listItem}>
                            <Checked /> <span className={styles.listItemText}>{item}</span>
                        </li>
                    );
                })}
            </ul>
            <div className={styles.mobileWrapperHidden}>
                <Separator variant="horizontal" />
            </div>
            <ul className={styles.listContainer}>
                {secondItems.map((item, i) => {
                    return (
                        <li key={`secondItems-${i}`} className={styles.listItem}>
                            <Checked /> <span className={styles.listItemText}>{item}</span>
                        </li>
                    );
                })}
            </ul>
            <div className={styles.buttonContainer}>
                <Button variant="secondary" fullWidth fullWidthDesktop onClick={handleClick}>
                    {actionButton.text}
                </Button>
            </div>
        </div>
    ) : (
        <div className={styles.wrapperHorizontal}>
            <div className={styles.leftTwoColumn}>
                <div className={styles.leftColumn}>
                    <Badge variant={badge.variant}>{badge.text}</Badge>
                    <p className={styles.title}>{title}</p>
                    <div className={styles.buttonContainerHorizontal}>
                        <Button variant="secondary" size="medium" fullWidth>
                            {actionButton.text}
                        </Button>
                    </div>
                </div>
                <ul className={styles.listContainerHorizontal}>
                    {items.map((item, i) => {
                        return (
                            <li key={`firstItems-${i}`} className={styles.listItem}>
                                <Checked /> <span className={styles.listItemText}>{item}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className={styles.mobileWrapperHidden}>
                <Separator variant="vertical" />
            </div>
            <ul className={styles.listContainerRight}>
                {secondItems.map((item, i) => {
                    return (
                        <li key={`secondItems-${i}`} className={styles.listItem}>
                            <Checked /> <span className={styles.listItemText}>{item}</span>
                        </li>
                    );
                })}
            </ul>
            <div className={styles.buttonContainerBottom}>
                <Button variant="secondary" fullWidth>
                    {actionButton.text}
                </Button>
            </div>
        </div>
    );
};
