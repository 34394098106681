import React from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { Formik, Form, Field, FieldProps } from "formik";

import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../assets/icons/chat.svg";
import { ReactComponent as Gmail } from "../../assets/icons/gmail.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { Button } from "../Button/Button";

import { buyPlanAsync, loginAsync } from "../../store/authSlice";
import { AppDispatch, RootState } from "../../store/store";
import { UserDetails } from "../../api/types";

import { translation } from "../../translations/translation";

import styles from "./LoginForm.module.scss";

export const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { error } = useSelector((state: RootState) => state.auth);
    const handleSubmit = async (values: { email: string; password: string }) => {
        try {
            const loginResult = await dispatch(loginAsync({ email: values.email, password: values.password }));
            if (loginResult.meta.requestStatus === "fulfilled" && loginResult.payload) {
                const user = loginResult.payload as UserDetails;
                if (user.is_first_login && user.payment_plan_id === 4) {
                    await dispatch(buyPlanAsync());
                } else {
                    navigate("/chats");
                }
            }
        } catch (error) {
            console.log("Błąd logowania:", error);
        }
    };

    return (
        <div className={styles.wrapper}>
            <a href="https://votrechatbot.fr/" className={styles.iconTextWrapper}>
                <Logo className={styles.icon} />
                <p className={styles.textWrapper}>{translation.global.appName}</p>
            </a>
            <div className={styles.formContainer}>
                {error && (
                    <Typography color="error" className={styles.loginError} gutterBottom>
                        {translation.loginPage.error}
                    </Typography>
                )}
                <Formik initialValues={{ email: "", password: "" }} onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <p className={styles.title}>{translation.global.login}</p>
                            <Field name="email">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={translation.loginPage.firstInputField}
                                            autoComplete="username"
                                            {...field}
                                            className={styles.textField}
                                        />
                                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                    </div>
                                )}
                            </Field>
                            <Field name="password">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <input
                                            type="password"
                                            autoComplete="current-password"
                                            placeholder={translation.loginPage.secondInputField}
                                            {...field}
                                            className={styles.textField}
                                        />
                                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                    </div>
                                )}
                            </Field>
                            <div className={styles.loginButtonContainer}>
                                <Button type="submit" variant="secondary" size="medium" fullWidth fullWidthDesktop>
                                    {translation.global.login}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className={styles.mediaButtons}>
                    <Button type="submit" variant="primary" size="medium" disabled fullWidth fullWidthDesktop>
                        <Gmail className={styles.icon} />
                        <p className={styles.separatorText}>{translation.global.googleButton}</p>
                    </Button>
                    <Button type="submit" variant="primary" size="medium" disabled fullWidth fullWidthDesktop>
                        <Facebook className={styles.icon} />
                        <p className={styles.separatorText}>{translation.global.fbButton}</p>
                    </Button>
                </div>
                <div className={styles.separator}>
                    <div className={styles.lineL} />
                    <p className={styles.separatorText}>{translation.loginPage.haveAccount}</p>
                    <div className={styles.lineP} />
                </div>
                <a href="https://votrechatbot.fr/#tarifs">
                    <Button type="submit" variant="primary" size="medium" fullWidth fullWidthDesktop>
                        {translation.global.register}
                    </Button>
                </a>
            </div>
            <div className={styles.footerContainer}>
                <p className={styles.footerText}>{`2023 © ${translation.global.appName}`}</p>
                <a href="https://votrechatbot.fr/politique-de-confidentialite/" className={styles.privatePolContainer}>
                    <p className={styles.footerText2}>{translation.global.privacyPolicy}</p>
                </a>
            </div>
        </div>
    );
};
