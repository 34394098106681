import { Translations } from "./translation.types";

export const translation: Translations = {
    global: {
        appName: "Votre Chat",
        conversations: "Conversations",
        chatbots: "Chatbots",
        database: "Base de connaissances",
        payment: "Effectuer un paiement",
        extend: "Augumenter le plan",
        hobby: "pour le plan HOBBY",
        hobbyShortcut: "H",
        enterprise: "pour le plan ENTREPRISE",
        enterpriseShortcut: "E",
        register: "Enregistrer",
        logout: "Se déconnecter",
        login: "Se connecter",
        googleButton: "Continuer avec Google",
        fbButton: "Continuer avec Facebook",
        privacyPolicy: "Politique de confidentialité",
        plans: {
            free: "Plan FREE",
            freeShortcut: "FREE",
            hobby: "Plan HOBBY",
            hobbyShortcut: "HOBBY",
            enterprise: "Plan D'ENTREPRISE",
            enterpriseShortcut: "ENTREPRISE",
        },
    },
    schemas: {
        documentRequired: "Le document est obligatoire",
        emaildInvalid: "Le format de l'e-mail est incorrect",
        fieldRequired: "Le champ est obligatoire",
    },
    chatbotsPage: {
        title: "Chatbots",
        subTitle: "Ici, vous pouvez gérer et interagir avec les chatbots.",
        actionButton: "Créez un chatbot",
        modal: {
            title: "Créez votre chatbot",
            subTitle: "et découvrir ses possibilités",
            firstInputField: "Nom chat",
            secondInputField: "Description",
            createBot: "Créez un chatbot",
            editBot: "Modifier le chatbot",
        },
        table: {
            name: "Nom",
            description: "Description",
            added: "Ajouté",
            actions: "Actions",
        },
        noChatbotsMessage: "Vous n'avez pas encore de chats",
    },
    conversationsPage: {
        title: "Conversations",
        noChatError: "Allez dans la section chatbots et sélectionnez celui avec lequel vous voulez parler",
        conversationsName: "Conversation no:",
        firstMessage: "Salut! comment je peux vous aider aujourd'hui?",
        sources: "Sources",
        askQuestion: "Posez une question...",
        chatTyping: "Chat écrit...",
    },
    documentsPage: {
        title: "Base de connaissances",
        actionButton: "Ajoutez les documents",
        done: "Prêt",
        pending: "Traitement ",
        table: {
            name: "Nom",
            added: "Ajouté",
            status: "Statut",
            actions: "Actions",
        },
        modal: {
            title: "Ajouter le document",
            subTitle: "Télécharger un document existant.",
            listTitle: "Dans quel est chat vous souhaitez ajouter des fichiers?",
            inputHeader: "Sélectionner le document:",
            inputFirstSentence: "Cliquer pour ajouter ",
            inputSecondSentence: "ou transférer ici",
            submitButton: "Ajouter un document",
        },
        noFilesMessage: "Aucun document ajouté.",
    },
    contactPage: {
        formTitle: "Napisz do nas wiadomość",
        firstInputField: "Votre nick",
        secondInputField: "Votre e-mail",
        textAreaDescription: "Messages",
        submitButton: "Envoyer",
        description:
            "Nous sommes une équipe de spécialistes expérimentés qui proposent des solutions innovantes et personnalisées basées sur les modèles linguistiques disponibles. Nous avons une excellente compréhension des défis auxquels sont confrontées les entreprises polonaises. Faites-nous part de vos besoins et nous prendrons en charge votre projet dans son intégralité, vous aidant ainsi à atteindre le succès.",
    },
    registerPage: {
        title: "S'inscrire",
        subTitle: "Nous vous suggérons de fournir l'adresse e-mail que vous utilisez au travail.",
        firstInputField: "E-mail",
        secondInputField: "Créer un mot de passe",
        haveAccount: "Vous avez déjà un compte?",
    },
    loginPage: {
        title: "Se connecter",
        error: "Tentative de connexion échouée. Vérifier le nom d’utilisateur et le mot de passe.",
        firstInputField: "E-mail",
        secondInputField: "Mot de passe",
        haveAccount: "Vous n’avez pas encore de compte ?",
    },
    notifications: {
        rateSuccess: "Message a été évalué.",
        rateError: "Erreur s'est produite lors de l'évaluation. Réessayez plus tard.",

        addDocumentSuccess: "L'ajout de fichier a été effectué avec succès.",

        addDocumentError: "Erreur s'est produite lors de l'ajout du fichier. Ajoutez d'abord le chatbot.",

        deleteDocumentSuccess: "Suppression du fichier terminé avec succès.",
        deleteDocumentError: "Erreur s'est produite lors de la suppression d'un fichier.",

        editChatbotSuccess: "L'édition du chatbot a été réalisée avec succès.",
        editChatbotError: "Erreur s'est produite lors de l'édition du chatbot.",
        deleteChatbotSuccess: "Suppression du chatbot terminée avec succès.",
        deleteChatbotError: "Erreur s'est produite lors de la suppression du chatbot.",

        submitContactFormSuccess: "Message a été envoyé.",
        submitContactFormError: "Erreur s'est produite, veuillez réessayer plus tard.",

        submitRegisterFormSuccess:
            "E-mail contenant le lien de confirmation a été envoyé. Vérifiez votre boîte e-mail..",
        registerFormNoPlanCheckedError: "Erreur s'est produite, sélectionnez le plan qui vous intéresse..",

        confirmEmailSuccess: "Votre e-mail a été confirmé. Vous pouvez se connecter.",

        addChatBotSuccess: "L'ajout d'un chatbot est terminé avec succès.",

        addFileSuccess: "L'ajout de fichiers a été terminé avec succès.",
        addFileError: "Erreur s'est produite lors de l'ajout du fichier. Le format requis est le PDF.",

        fetchConversationsError: "Erreur s'est produite lors du téléchargement de l'historique de la conversation..",

        logoutSuccess: "Déconnexion terminée avec succès.",
    },
};
