import React, { useState } from "react";

import { Link as RouterLink } from "react-router-dom";

import { Link } from "react-scroll";

import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Logo } from "../../assets/icons/chat.svg";
import { Button } from "../Button/Button";

import { selectAccessToken } from "../../store/authSlice";
import { useLogout } from "../../utils/useLogout";
import { AppDispatch } from "../../store/store";

import { translation } from "../../translations/translation";

import styles from "./Navbar.module.scss";

export const Navbar = () => {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch<AppDispatch>();
    const logout = useLogout();

    const handleToggle = () => {
        setIsBurgerOpen(!isBurgerOpen);
    };

    const handleLogout = () => {
        dispatch(logout);
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.leftWrapper}>
                    <RouterLink to={"/"} className={styles.routerLinkLogo}>
                        <div className={styles.iconWrapper}>
                            <Logo />
                        </div>
                        <p className={styles.textWrapper}>{translation.global.appName}</p>
                    </RouterLink>
                </div>
                <nav className={isBurgerOpen ? styles.burgerNavigationOpen : styles.burgerNavigation}>
                    <ul className={isBurgerOpen ? styles.burgerNavigationContainer : styles.navigation}>
                        <li className={styles.navigationItem}>
                            <Link to="howItWorks" spy smooth offset={-20} duration={500} className={styles.singleLink}>
                                Jak to działa?
                            </Link>
                        </li>
                        <li className={styles.navigationItem}>
                            <Link to="benefits" spy smooth offset={50} duration={500} className={styles.singleLink}>
                                Korzyści
                            </Link>
                        </li>
                        <li className={styles.navigationItem}>
                            <Link to="prices" spy smooth offset={-20} duration={500} className={styles.singleLink}>
                                Cennik
                            </Link>
                        </li>
                        <li className={styles.navigationItem}>
                            <Link to="faq" spy smooth offset={-50} duration={500} className={styles.singleLink}>
                                FAQ
                            </Link>
                        </li>
                        {isBurgerOpen && !accessToken && (
                            <li className={styles.navigationItemExtra}>
                                <RouterLink to="/login">
                                    <Button variant="quaternary" size="large" fullWidth>
                                        {translation.global.login}
                                    </Button>
                                </RouterLink>
                            </li>
                        )}
                    </ul>
                </nav>
                <div className={styles.rightWrapper}>
                    {accessToken ? (
                        <div className={styles.firstButtonContainer}>
                            <Button onClick={() => handleLogout()}>{translation.global.logout}</Button>
                        </div>
                    ) : (
                        <div className={styles.firstButtonContainer}>
                            <RouterLink to="/login">
                                <Button data-testid="loginButton">{translation.global.login}</Button>
                            </RouterLink>
                        </div>
                    )}
                    {accessToken ? (
                        <div className={styles.firstButtonContainer}>
                            <RouterLink to="/chats">
                                <Button variant="secondary">Czaty</Button>
                            </RouterLink>
                        </div>
                    ) : (
                        <Link to="prices" spy smooth duration={500}>
                            <Button variant="secondary">Stwórz chatbota</Button>
                        </Link>
                    )}

                    <button
                        type="button"
                        onClick={handleToggle}
                        className={`${styles.burger} ${isBurgerOpen ? styles.burgerOpen : undefined}`}
                        data-testid="burgerButton"
                    >
                        <span className={styles.burgerLine} />
                        <span className={styles.burgerLine} />
                        <span className={styles.burgerLine} />
                    </button>
                </div>
            </div>
        </div>
    );
};
