import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { LandingPage } from "./pages/LandingPage/LandingPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { RegisterPage } from "./pages/RegisterPage/RegisterPage";
import { AddChatPage } from "./pages/AddChatPage/AddChatPage";
import { ProtectedRoute } from "./utils/ProtectedRouter";
import { ChatsPage } from "./pages/ChatsPage/ChatsPage";
import { ContactUs } from "./components/ContactForm/ContactForm";
import { ConfirmEmailPage } from "./pages/ConfirmEmail/ConfirmEmailPage";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/register/:id" element={<RegisterPage />} />
                <Route
                    path="/chats"
                    element={
                        <ProtectedRoute>
                            <ChatsPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/add"
                    element={
                        <ProtectedRoute>
                            <AddChatPage />
                        </ProtectedRoute>
                    }
                />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/authorization/confirm_email/:confirmation_code" element={<ConfirmEmailPage />} />
                <Route path="*" element={<h1>Error 404</h1>} />
            </Routes>
        </Router>
    );
}

export default App;
