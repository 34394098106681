import React from "react";

import { SectionHeader } from "../../SectionHeader/SectionHeader";
import { InfoCard } from "../../InfoCard/InfoCard";
import elipse from "../../../assets/images/ellipse.png";
import elipse2 from "../../../assets/images/ellipse2.png";

import styles from "./FourthSection.module.scss";

export const FourtSection = () => {
    return (
        <div className={styles.wrapper} id="prices">
            <div className={styles.sectionHeaderContainer}>
                <SectionHeader
                    variant="secondary"
                    title="Dostosuj plan do swoich potrzeb"
                    badge={{ text: "Cennik", variant: "tertiary" }}
                    textAlign="center"
                />
            </div>
            <img src={elipse} alt="cssEffect" className={styles.ellipse} />
            <img src={elipse2} alt="cssEffect" className={styles.ellipse2} />
            <div className={styles.infoCardsWrapper}>
                <InfoCard
                    planId={1}
                    title="0zl/msc"
                    items={[
                        "1 chatbot",
                        "400 000 znaków na chatbota",
                        "Prześlij dwa pliki",
                        "Wyświetl historię rozmów",
                    ]}
                    secondItems={["Chatboty są usuwane po 7 dniach braku aktywności"]}
                    badge={{ text: "FREE", variant: "tertiary" }}
                    actionButton={{ text: "Stwórz chata" }}
                />
                <InfoCard
                    hasPointer
                    planId={2}
                    title="30zl/msc"
                    items={[
                        "2 chatboty",
                        "11 000 000 znaków na chatbota",
                        "Prześlij wiele plików",
                        "Wyświetl historię rozmów",
                    ]}
                    secondItems={[
                        "Dostęp do API (wkrótce)",
                        "Integracja z Zapierem (wkrótce)",
                        "Integracja Slacka (wkrótce)",
                        "Integracja z Wordpressem (wkrótce)",
                        "Integracja z komunikatorem (wkrótce)",
                        "Integracja z WhatsApp (wkrótce)",
                    ]}
                    badge={{ text: "HOBBY", variant: "tertiary" }}
                    actionButton={{ text: "Subskrybuj" }}
                />
                <InfoCard
                    planId={3}
                    title="Wycena indywidualna"
                    items={[
                        "5 chatbotów",
                        "11 000 000 znaków na chatbota",
                        "Prześlij wiele plików",
                        "Wyświetl historię rozmów",
                    ]}
                    secondItems={[
                        "Dostęp do API (wkrótce)",
                        "Integracja z Zapierem (wkrótce)",
                        "Integracja Slacka (wkrótce)",
                        "Integracja z Wordpressem (wkrótce)",
                        "Integracja z komunikatorem (wkrótce)",
                        "Integracja z WhatsApp (wkrótce)",
                    ]}
                    badge={{ text: "ENTERPRISE", variant: "tertiary" }}
                    actionButton={{ text: "Zostaw kontakt" }}
                />
            </div>
        </div>
    );
};
