import React from "react";

import { Link as RouterLink } from "react-router-dom";

import { Formik, Form, Field, FieldProps } from "formik";

import * as Yup from "yup";

import { ReactComponent as Logo } from "../../assets/icons/chat.svg";
import { Button } from "../Button/Button";

import { addChat } from "../../api/requests/requests";

import { translation } from "../../translations/translation";

import styles from "./CreateChatForm.module.scss";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(translation.schemas.fieldRequired),
    description: Yup.string().required(translation.schemas.fieldRequired),
});

export const CreateChatForm = () => {
    const handleSubmit = async (values: { name: string; description: string }) => {
        try {
            await addChat(values.name, values.description);
        } catch (error) {
            console.log("Błąd logowania:", error);
        }
    };

    return (
        <div className={styles.wrapper}>
            <RouterLink to="/chats" className={styles.iconTextWrapper}>
                <Logo className={styles.icon} />
                <p className={styles.textWrapper}>{translation.global.appName}</p>
            </RouterLink>
            <div className={styles.formContainer}>
                <Formik
                    initialValues={{ name: "", description: "" }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {() => (
                        <Form data-testid="form">
                            <p className={styles.title}>{translation.chatbotsPage.title}</p>
                            <p className={styles.subTitle}>{translation.chatbotsPage.subTitle}</p>
                            <Field name="name">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={translation.chatbotsPage.modal.firstInputField}
                                            {...field}
                                            className={styles.textField}
                                        />
                                        {meta.touched && meta.error && (
                                            <div data-testid="name-error" className="error">
                                                {meta.error}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <Field name="description">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={translation.chatbotsPage.modal.secondInputField}
                                            {...field}
                                            className={styles.textField}
                                        />
                                        {meta.touched && meta.error && (
                                            <div data-testid="description-error" className="error">
                                                {meta.error}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Field>
                            <div className={styles.createButtonContainer}>
                                <Button type="submit" variant="secondary" size="medium" fullWidth fullWidthDesktop>
                                    {translation.chatbotsPage.modal.createBot}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className={styles.footerContainer}>
                <p className={styles.footerText}>{`2023 © ${translation.global.appName}`}</p>
                <a href="https://votrechatbot.fr/politique-de-confidentialite/" className={styles.privatePolContainer}>
                    <p className={styles.footerText2}>{translation.global.privacyPolicy}</p>
                </a>
            </div>
        </div>
    );
};
