import React from "react";

import { Link } from "react-scroll";

import { Button } from "../Button/Button";

import { ReactComponent as Receipt } from "../../assets/icons/receipt.svg";

import styles from "./Card.module.scss";

import { CardProps } from "./Card.types";

export const Card = ({ subTitle, title, hasBorder, actionButton, dataTestid }: CardProps) => {
    return (
        <div
            className={hasBorder ? styles.wrapperBordered : actionButton ? styles.wrapperHeight : styles.wrapper}
            data-testid={dataTestid}
        >
            <div className={styles.innerWrapper}>
                <div>
                    <div className={styles.iconWrapper}>
                        <Receipt className={styles.icon} data-testid="receiptIcon" />
                    </div>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.subTitle}>{subTitle}</p>
                </div>
                {actionButton &&
                    (actionButton.href ? (
                        <Link data-testid={"dataTo"} to={actionButton.href} spy smooth offset={-50} duration={500}>
                            <Button size="large" variant="secondary">
                                {actionButton.text}
                            </Button>
                        </Link>
                    ) : (
                        <Button size="large" variant="secondary" onClick={actionButton.action}>
                            {actionButton.text}
                        </Button>
                    ))}
            </div>
        </div>
    );
};
