// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Separator_separator__cpSDq {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.Separator_line__zBGhc {
  flex-grow: 1;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-left: -40px;
  margin-right: -40px;
}

.Separator_separatorVertical__qvuQV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 40px;
}

.Separator_lineVertical__jacmA {
  width: 1px;
  background-color: rgba(255, 255, 255, 0.05);
  height: 100px;
}

.Separator_icon__MEmI2 {
  z-index: 1;
  color: #0EB77A;
  background-color: #E1F9F0;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/Separator/Separator.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,YAAA;EACA,WAAA;EACA,2CAAA;EACA,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;AADJ;;AAIA;EACI,UAAA;EACA,2CAAA;EACA,aAAA;AADJ;;AAIA;EACI,UAAA;EACA,cCvBK;EDwBL,yBCtBK;EDuBL,kBAAA;EACA,YAAA;EACA,WAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors; \n\n.separator {\n    display: flex;\n    align-items: center;\n    margin-top: 24px;\n}\n  \n.line {\n    flex-grow: 1;\n    height: 1px;\n    background-color: rgba(255, 255, 255, 0.05);\n    margin-left: -40px;\n    margin-right: -40px;\n}\n\n.separatorVertical {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    margin-left: 40px;\n}\n  \n.lineVertical {\n    width: 1px;\n    background-color: rgba(255, 255, 255, 0.05);\n    height: 100px;\n}\n\n.icon {\n    z-index: 1;\n    color: colors.$green0;\n    background-color: colors.$green5;\n    border-radius: 50%;\n    height: 32px;\n    width: 32px;\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `Separator_separator__cpSDq`,
	"line": `Separator_line__zBGhc`,
	"separatorVertical": `Separator_separatorVertical__qvuQV`,
	"lineVertical": `Separator_lineVertical__jacmA`,
	"icon": `Separator_icon__MEmI2`
};
export default ___CSS_LOADER_EXPORT___;
