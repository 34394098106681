// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionHeader_title__DNzBh {
  font-size: 40px;
  padding-top: 8px;
  color: #272E35;
}
@media (max-width: 1024px) {
  .SectionHeader_title__DNzBh {
    font-size: 24px;
  }
}

.SectionHeader_titleSecondary__703xw {
  font-size: 40px;
  color: #FFFFFF;
}
@media (max-width: 1024px) {
  .SectionHeader_titleSecondary__703xw {
    font-size: 24px;
  }
}

.SectionHeader_subTitle__E6Okv {
  color: #272E35;
  margin-top: 16px;
}

.SectionHeader_subTitleSecondary__uuTm6 {
  color: #555F6D;
  margin-top: 16px;
}

.SectionHeader_center__HpDHw {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #272E35;
}`, "",{"version":3,"sources":["webpack://./src/components/SectionHeader/SectionHeader.module.scss","webpack://./src/styles/colors.scss","webpack://./src/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,gBAAA;EACA,cCJM;ADEV;AEFQ;EFCR;IAMQ,eAAA;EADN;AACF;;AAIA;EACI,eAAA;EACA,cCfI;ADcR;AEZQ;EFWR;IAKQ,eAAA;EAAN;AACF;;AAGA;EACI,cCrBM;EDsBN,gBAAA;AAAJ;;AAGA;EACI,cCvBQ;EDwBR,gBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cClCM;ADkCV","sourcesContent":["@use '../../styles/colors' as colors; \n@use '../../styles/responsive' as rwd; \n\n.title {\n    font-size: 40px;\n    padding-top: 8px;\n    color: colors.$primary;\n    \n    @include rwd.breakpoint(small) {\n        font-size: 24px;\n    }\n}\n\n.titleSecondary {\n    font-size: 40px;\n    color: colors.$white;\n    \n    @include rwd.breakpoint(small) {\n        font-size: 24px;\n    }\n}\n\n.subTitle {\n    color: colors.$primary;\n    margin-top: 16px;\n}\n\n.subTitleSecondary {\n    color: colors.$secondary;\n    margin-top: 16px;\n}\n\n.center {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: colors.$primary;\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;","@mixin breakpoint($point) {\n    @if $point == small {\n        @media (max-width:  1024px) { @content; }\n    }\n    // @else if $point == medium {\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\n    // }\n    @else if $point == large {\n        @media (min-width:  1025px) { @content; }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SectionHeader_title__DNzBh`,
	"titleSecondary": `SectionHeader_titleSecondary__703xw`,
	"subTitle": `SectionHeader_subTitle__E6Okv`,
	"subTitleSecondary": `SectionHeader_subTitleSecondary__uuTm6`,
	"center": `SectionHeader_center__HpDHw`
};
export default ___CSS_LOADER_EXPORT___;
