interface CardData {
    title: string;
    desc: string;
}

export const CardsData: CardData[] = [
    {
        title: "Uzyskaj odpowiedzi z dużych zbiorów tekstu",
        desc: "Męczy Cię przeglądanie niekończących się stron dokumentów w poszukiwaniu potrzebnych informacji? Rozwiązanie jest proste - wrzuć dokument i zapytaj chatbota.",
    },
    {
        title: "Innowacyjna metoda współpracy w zespołach",
        desc: "Kształć, wdrażaj i wspieraj swoje zespoły dzięki chatbotom opartym na firmowych dokumentach. Czy kiedykolwiek wyobrażałeś sobie chatbota będącego kodeksem postępowania czy procedurą obsługi klienta?",
    },
    {
        title: "Spraw, by nauka była bardziej angażująca i przystępna",
        desc: "Wrzuć swoje materiały instruktażowe, notatki, zasoby szkoleniowe. Chatbot wesprze Cię w nauce. Może wyjaśnić Ci trudne pojęcia i zaproponować  ćwiczenia oraz stworzyć przykłady, tak by pomóc Ci zastosować teorię w praktyce.",
    },
    {
        title: "Bezproblemowa nawigacja po skomplikowanych dokumentach",
        desc: "Skomplikowany język prawniczy lub techniczny może być wyzwaniem, ale nie dla naszych chatbotów. Wgrywaj umowy, przepisy, orzeczenia sądowe czy oficjalne poradniki i z łatwością z nich korzystaj.",
    },
];
