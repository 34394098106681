import React from "react";

import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/chat.svg";

import { ReactComponent as Email } from "../../assets/icons/email.svg";

import styles from "./Footer.module.scss";

export const Footer = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.iconTextWrapper}>
                    <Logo className={styles.icon} />
                    <p className={styles.textWrapper}>Twój Chat</p>
                </div>
                <p className={styles.textWrapper2}>2023 © TwójChat</p>
                <RouterLink to="/politykaprywatnosci" className={styles.privatePolContainer} data-testid="routerLink">
                    <p className={styles.textWrapper3}>Polityka prywatności</p>
                </RouterLink>
            </div>
            <div className={styles.rightWrapper}>
                <p className={styles.emailWrapper}>
                    <Email className={styles.icon} />
                    kontakt@twoj.chat
                </p>
                {/* <p className={styles.phoneWrapper}>
          <Phone className={styles.icon} />
          +48 500 500 500
        </p> */}
            </div>
        </div>
    );
};
