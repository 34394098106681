// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Badge_basic__emNah {
  display: flex;
  align-items: center;
  border-radius: 999px;
  padding: 0 8px;
  height: 24px;
  width: max-content;
}

.Badge_primary__VJQvx {
  background-color: #F4EAD7;
  color: #272E35;
}

.Badge_secondary__fUBFX {
  background-color: transparent;
  color: #FFFFFF;
}

.Badge_tertiary__h3GxB {
  background-color: rgba(255, 255, 255, 0.05);
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/Badge/Badge.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ;;AAIA;EAEI,yBCNO;EDOP,cCZM;ADWV;;AAIA;EAEI,6BAAA;EACA,cCpBI;ADmBR;;AAIA;EAEI,2CAAA;EACA,cC1BI;ADyBR","sourcesContent":["@use '../../styles/colors' as colors; \n\n.basic {\n    display: flex;\n    align-items: center;\n    border-radius: 999px;\n    padding: 0 8px;\n    height: 24px;\n    width: max-content;\n}\n\n.primary {\n    composes: basic;\n    background-color: colors.$emerald5;\n    color: colors.$primary;\n}\n\n.secondary {\n    composes: basic;\n    background-color: transparent;\n    color: colors.$white;\n}\n\n.tertiary {\n    composes: basic;\n    background-color: rgba(255, 255, 255, 0.05);\n    color: colors.$white;\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basic": `Badge_basic__emNah`,
	"primary": `Badge_primary__VJQvx Badge_basic__emNah`,
	"secondary": `Badge_secondary__fUBFX Badge_basic__emNah`,
	"tertiary": `Badge_tertiary__h3GxB Badge_basic__emNah`
};
export default ___CSS_LOADER_EXPORT___;
