import React from "react";

import { Link } from "react-scroll";

import { Button } from "../../Button/Button";
import elipse from "../../../assets/images/ellipse.png";
import elipse2 from "../../../assets/images/ellipse2.png";

import styles from "./SixthSection.module.scss";

export const SixthSection = () => {
    return (
        <div className={styles.wrapper}>
            <img src={elipse} alt="cssEffect" className={styles.ellipse} />
            <img src={elipse2} alt="cssEffect" className={styles.ellipse2} />
            <p className={styles.title}>
                Uzyskuj odpowiedzi
                <br />z Twoich dokumentów.
                <br />
                <span className={styles.titleSecondPart}>Natychmiastowo. </span>
            </p>
            <div className={styles.buttonContainer}>
                <Link to="prices" spy smooth duration={500}>
                    <Button size="large" variant="secondary" fullWidth>
                        Stwórz chatbota
                    </Button>
                </Link>
            </div>
        </div>
    );
};
