import React, { FC, useState } from "react";

import { Chat } from "../../../components/Chat/Chat";

import { ConversationsList } from "../../../components/ConversationList/ConversationsList";

import { translation } from "../../../translations/translation";

import styles from "./Conversations.module.scss";
import { ConversationsProps } from "./Conversations.types";

export const Conversations: FC<ConversationsProps> = () => {
    useState<boolean>(false);

    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>{translation.conversationsPage.title}</p>
            <div className={styles.wrapperInner}>
                <div className={styles.leftWrapper}>
                    <ConversationsList />
                </div>
                <div className={styles.rightWrapper}>
                    <Chat />
                </div>
            </div>
        </div>
    );
};
