import React from "react";

import { Badge } from "../Badge/Badge";

import styles from "./SectionHeader.module.scss";
import { SectionHeaderProps } from "./SectionHeader.types";

export const SectionHeader = ({
    badge,
    title,
    subTitle,
    textAlign = "left",
    variant = "primary",
}: SectionHeaderProps) => {
    return (
        <div className={styles[textAlign]}>
            <Badge variant={badge.variant}>{badge.text}</Badge>
            <p className={variant === "primary" ? styles.title : styles.titleSecondary}>{title}</p>
            {subTitle && (
                <p className={variant === "primary" ? styles.subTitle : styles.subTitleSecondary}>{subTitle}</p>
            )}
        </div>
    );
};
