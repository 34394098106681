import React, { useRef } from "react";
import { sendForm } from "@emailjs/browser";
import { Formik, Form, Field, FieldProps } from "formik";

import { useDispatch } from "react-redux";

import { CircularProgress } from "@mui/material";

import { Button } from "../Button/Button";
import { Layout } from "../Layout/Layout";
import { showNotification } from "../../store/notificationSlice";

import { ReactComponent as Logo } from "../../assets/icons/chat.svg";

import { translation } from "../../translations/translation";

import styles from "./ContactForm.module.scss";

export const ContactUs = () => {
    const dispatch = useDispatch();
    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (
        values: { message: string; user_email: string; user_name: string },
        setSubmitting: (isSubmitting: boolean) => void,
    ) => {
        // e.preventDefault();
        sendForm(
            process.env.REACT_APP_EMAIL ?? "",
            "template_4ax5mls",
            //@ts-ignore
            form.current,
            "qWicwKALW8a6USncW",
        )
            .then(
                () => {
                    dispatch(
                        showNotification({
                            variant: "success",
                            title: "Success!",
                            subtitle: translation.notifications.submitContactFormSuccess,
                        }),
                    );
                },
                (error) => {
                    dispatch(
                        showNotification({
                            variant: "error",
                            title: "Error!",
                            subtitle: translation.notifications.submitContactFormError,
                        }),
                    );
                    console.log(error.text);
                },
            )
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Layout>
            <div className={styles.wrapper}>
                <a href="https://votrechatbot.fr/" className={styles.iconTextWrapper}>
                    <Logo className={styles.icon} />
                    <p className={styles.textWrapper}>{translation.global.appName}</p>
                </a>
                <p className={styles.textDescWrapper}>{translation.contactPage.description}</p>
                <div className={styles.formContainer}>
                    <Formik
                        initialValues={{ message: "", user_email: "", user_name: "" }}
                        onSubmit={(values, { setSubmitting }) => sendEmail(values, setSubmitting)}
                    >
                        {({ isSubmitting }) => (
                            <Form ref={form}>
                                <p className={styles.title}>{translation.contactPage.formTitle}</p>
                                <Field name="user_name">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={translation.contactPage.firstInputField}
                                                {...field}
                                                className={styles.textField}
                                            />
                                            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="user_email">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={translation.contactPage.secondInputField}
                                                {...field}
                                                className={styles.textField}
                                            />
                                            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="message">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <textarea
                                                placeholder={translation.contactPage.textAreaDescription}
                                                {...field}
                                                className={styles.textArea}
                                            />
                                            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                        </div>
                                    )}
                                </Field>
                                <div className={styles.loginButtonContainer}>
                                    {isSubmitting ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            size="medium"
                                            fullWidth
                                            fullWidthDesktop
                                        >
                                            {translation.contactPage.submitButton}
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={styles.footerContainer}>
                    <p className={styles.footerText}>{`2023 © ${translation.global.appName}`}</p>
                    <a
                        href="https://votrechatbot.fr/politique-de-confidentialite/"
                        className={styles.privatePolContainer}
                        data-testid="routerLinkPolicy"
                    >
                        <p className={styles.footerText2}>{translation.global.privacyPolicy}</p>
                    </a>
                </div>
            </div>
        </Layout>
    );
};
