import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { ExpanderProps } from "./Expander.types";
import styles from "./Expander.module.scss";

export const Expander = ({ answer, question }: ExpanderProps) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };
    return (
        <Accordion
            expanded={isAccordionOpen}
            elevation={0}
            disableGutters={true}
            onChange={toggleAccordion}
            sx={{
                "&:before": { height: "0px" },
                "&:last-of-type": { borderRadius: "16px" },
                "&:first-of-type": { borderRadius: "16px" },
                ".MuiAccordionSummary-root": {
                    minHeight: "32px",
                },
                borderRadius: "16px",
                marginTop: "16px",
            }}
            className={styles.accordion}
        >
            <AccordionSummary
                expandIcon={
                    isAccordionOpen ? <RemoveIcon className={styles.icon} /> : <AddIcon className={styles.icon} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={styles.accordionSummary}
                sx={{
                    ".MuiAccordionSummary-content": { margin: 0 },
                }}
            >
                {question}
            </AccordionSummary>
            <AccordionDetails className={styles.details}>{answer}</AccordionDetails>
        </Accordion>
    );
};
