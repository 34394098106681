import React from "react";

import { Link } from "react-scroll";

import { SectionHeader } from "../../SectionHeader/SectionHeader";

import { Card } from "../../Card/Card";

import { Button } from "../../Button/Button";

import styles from "./ThirdSection.module.scss";
import { CardsData } from "./ThirdSection.data";

export const ThirdSection = () => {
    return (
        <div className={styles.wrapper} id="benefits">
            <div className={styles.sectionHeaderContainer}>
                <SectionHeader
                    title={"Stworzony dla Ciebie i Twojego zespołu"}
                    badge={{ text: "Korzyści", variant: "primary" }}
                    textAlign="center"
                />
            </div>
            <div className={styles.cardsContainer}>
                {CardsData.map(({ desc, title }, i) => {
                    return <Card key={i} title={title} subTitle={desc} hasBorder />;
                })}
            </div>
            <div className={styles.buttonsContainer}>
                <Link to="prices" spy smooth duration={500}>
                    <Button size="large" variant="secondary" fullWidth>
                        Stwórz chatbota
                    </Button>
                </Link>
                <Link to="faq" spy smooth offset={-50} duration={500}>
                    <Button size="large" variant="primary" fullWidth>
                        FAQ
                    </Button>
                </Link>
            </div>
        </div>
    );
};
