import React from "react";

import { Link } from "react-scroll";

import { Button } from "../../Button/Button";
import elipse2 from "../../../assets/images/ellipse2.png";

import styles from "./FirstSection.module.scss";

export const FirstSection = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.innerWapper}>
                <img src={elipse2} alt="cssEffect" className={styles.ellipse2} />
                <div className={styles.left}>
                    <p className={styles.title}>
                        Uzyskuj odpowiedzi z Twoich dokumentów.
                        <br />
                        <span className={styles.titleSecondPart}>Natychmiastowo. </span>
                    </p>
                    <p className={styles.subTitle}>
                        Twój Chat, to szybki oraz łatwy w użyciu asystent do czytania dużych plików oparty na ChatGPT,
                        który zlokalizuje, wyodrębni i podsumuje informacje z Twoich dokumentów. Wystarczy zapytać.
                    </p>
                    <div className={styles.buttonsContainer}>
                        <Link to="prices" spy smooth offset={-50} duration={500} className={styles.singleLink}>
                            <Button variant="secondary" size="large" fullWidth>
                                Stwórz chatbota
                            </Button>
                        </Link>
                        <Link to="howItWorks" spy smooth offset={-50} duration={500} className={styles.singleLink}>
                            <Button size="large" variant="tertiary" fullWidth>
                                Jak to działa?
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className={styles.photo} />
            </div>
        </div>
    );
};
