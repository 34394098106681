import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addChat, getChatList } from "../api/requests/requests";
import { Chat } from "../api/types";

import { translation } from "../translations/translation";

import { showNotification } from "./notificationSlice";

interface ChatBotState {
    chatBotList: Chat[];
    loading: boolean;
    currentChat?: Chat;
    error?: string;
}

export const initialState: ChatBotState = {
    chatBotList: [],
    loading: false,
    error: "",
};

export const addBotAsync = createAsyncThunk(
    "bots/addBotAsync",
    async (userData: { name: string; description: string }, { dispatch }) => {
        try {
            await addChat(userData.name, userData.description);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: translation.notifications.addChatBotSuccess,
                }),
            );
            return await getChatList();
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle:
                        //@ts-ignore
                        error.response.data.detail,
                }),
            );
            throw error;
        }
    },
);

export const fetchBotList = createAsyncThunk("bots/fetchBotList", async () => {
    try {
        const response = await getChatList();
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
});

const chatsSlice = createSlice({
    name: "bots",
    initialState,
    reducers: {
        clearBots: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addBotAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(addBotAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.chatBotList = action.payload;
            })
            .addCase(addBotAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchBotList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBotList.fulfilled, (state, action) => {
                state.loading = false;
                state.chatBotList = action.payload;
            })
            .addCase(fetchBotList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearBots } = chatsSlice.actions;
export default chatsSlice.reducer;
