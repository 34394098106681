import React from "react";

import AddIcon from "@mui/icons-material/Add";

import styles from "./Separator.module.scss";
import { SeparatorProps } from "./Separator.types";

export const Separator = ({ variant }: SeparatorProps) => {
    return (
        <div className={variant === "vertical" ? styles.separatorVertical : styles.separator} data-testid="separator">
            <div className={variant === "vertical" ? styles.lineVertical : styles.line} data-testid="line" />
            <AddIcon className={styles.icon} data-testid="icon" />
            <div className={variant === "vertical" ? styles.lineVertical : styles.line} data-testid="line" />
        </div>
    );
};
