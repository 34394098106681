import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";

import { MessageObject } from "../components/Chat/Chat.types";

import { getOldConversations } from "../api/requests/requests";

import { translation } from "../translations/translation";

import { RootState } from "./store";
import { showNotification } from "./notificationSlice";

interface ChatState {
    messages: MessageObject[];
    isRequesting?: boolean;
    isConversationsLoading?: boolean;
    error?: SerializedError;
}

export const initialState: ChatState = {
    messages: [],
    isRequesting: false,
    error: undefined,
};

export const fetchConversationHistory = createAsyncThunk(
    "conversations/fetchConversationHistory",
    async (converstaionId: number, { dispatch }) => {
        try {
            const response = await getOldConversations(converstaionId);
            return response;
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    //@ts-ignore
                    subtitle: translation.notifications.fetchConversationsError,
                }),
            );
            throw error;
        }
    },
);

const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<MessageObject>) => {
            state.messages.push(action.payload);
        },
        editLastMessage: (state, action: PayloadAction<Partial<MessageObject>>) => {
            const lastMessageIndex = state.messages.length - 1;
            if (lastMessageIndex >= 0) {
                state.messages[lastMessageIndex] = {
                    ...state.messages[lastMessageIndex],
                    ...action.payload,
                };
            }
        },
        editMessageById: (
            state,
            action: PayloadAction<{ messageId: number; updatedMessage: Partial<MessageObject> }>,
        ) => {
            const { messageId, updatedMessage } = action.payload;
            const foundMessageIndex = state.messages.findIndex((message) => message.id === messageId);
            if (foundMessageIndex) {
                state.messages[foundMessageIndex] = {
                    ...state.messages[foundMessageIndex],
                    ...updatedMessage,
                };
            }
        },
        clearMessages: () => {
            return initialState;
        },
        isRequesting: (state, action: PayloadAction<boolean>) => {
            state.isRequesting = action.payload;
        },
        setMessagesFromSpecificConversation: (state, action: PayloadAction<MessageObject[]>) => {
            state.messages = [];
            state.messages = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConversationHistory.pending, (state) => {
                state.isConversationsLoading = true;
            })
            .addCase(fetchConversationHistory.fulfilled, (state, action) => {
                state.isConversationsLoading = false;
                state.messages = [];
                state.messages = action.payload;
            })
            .addCase(fetchConversationHistory.rejected, (state, action) => {
                state.isConversationsLoading = false;
                state.messages = [];
                state.error = action.error;
            });
    },
});

export const {
    addMessage,
    clearMessages,
    isRequesting,
    editLastMessage,
    editMessageById,
    setMessagesFromSpecificConversation,
} = messagesSlice.actions;

export const selectMessages = (state: RootState) => state.messages.messages;
export const isTyping = (state: RootState) => state.messages.isRequesting;

export default messagesSlice.reducer;
