import React from "react";

import { Link } from "react-router-dom";

import { SectionHeader } from "../../SectionHeader/SectionHeader";

import { Expander } from "../../Expander/Expander";
import { Button } from "../../Button/Button";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";

import styles from "./FifthSection.module.scss";

export const FifthSection = () => {
    return (
        <div className={styles.wrapper} id="faq">
            <div className={styles.sectionHeaderContainer}>
                <SectionHeader
                    badge={{ text: "FAQ", variant: "primary" }}
                    title="Najczęściej zadawane pytania"
                    subTitle="Wszystko, co musisz wiedzieć na temat Twojego Chatu"
                    textAlign="center"
                />
            </div>
            <div className={styles.expandersWrapper}>
                <Expander
                    question="Czym jest rozwiązanie twoj.chat?"
                    answer="Twoj.chat to narzędzie oparte na sztucznej inteligencji, które umożliwia tworzenie chatbotów na podstawie dostarczonych dokumentów. Dzięki temu możemy szybko analizować dokumenty oraz natychmiastowo uzyskiwać potrzebne informacje."
                />
                <Expander
                    question="Czy mogę skorzystać z twoj.chat za darmo?"
                    answer={
                        <>
                            Tak, plan FREE pozwala na stworzenie 1 chatbota (400 tys. znaków) na bazie dwóch plików PDF
                            o wielkości do 2 MB. Przetestuj nasze rozwiązanie i sprawdź w jaki sposób może Ci pomóc w
                            twoich codziennych zadaniach.
                            <br />
                            <br />
                            Masz już pomysł jak twoj.chat mógłby zostać dostosowany do twoich potrzeb? Przekaż nam
                            feedback,
                            <Link to="/contact">skontaktuj się z nami,</Link>
                            aby porozmawiać o współpracy.
                        </>
                    }
                />
                <Expander
                    question="Jak działa twoj.chat?"
                    answer="System analizuje dostarczone dokumenty. Przetwarzanie danych nie trwa długo, już po chwili możliwe jest stworzenie chatbota, który bazuje na podanym dokumencie. Możesz zadać mu pytanie, a twój czat odpowie zgodnie z informacjami zawartymi w dokumencie i poda ich źródło."
                />
                <Expander
                    question="Jak krok po kroku stworzyć własnego chatbota?"
                    answer={
                        <>
                            Na stronie głównej kliknij przycisk “Stwórz chatbota”, a następnie wybierz plan. Zarejestruj
                            się i pamiętaj o potwierdzeniu swojego adresu email, klikając w link w przesłanym do Ciebie
                            mailu. <br />
                            <br />
                            Gdy już się zalogujesz, kliknij w Chatboty i +Stwórz chatbota, teraz możesz nadać mu nazwę i
                            opis. Gdy już to wykonasz, możesz dodać swój dokument do bazy wiedzy. <br />
                            <br />
                            Teraz otwórz konwersację ze swoim dokumentem i zobacz jak prosta może stać się nawigacja po
                            złożonej treści!
                        </>
                    }
                />
                <Expander
                    question="Na bazie jakich dokumentów mogę stworzyć chatbota?"
                    answer={
                        <>
                            Zgodnie z planem FREE, możesz dodać dwa pliki PDF o wielkości do 2 MB. Plan HOBBY pozwala na
                            dodanie wielu plików o wielkości do 4 MB. Jeżeli chcesz tworzyć czatboty na bazie większych
                            plików lub zastosować czat w swoim biznesie w bardziej zaawansowany sposób,{" "}
                            <Link to="/contact">skontaktuj się z nami</Link>.
                            <br />
                            <br />
                            Chatbot analizuje tekst i tabele, które znajdują się w dokumencie. Dane znajdujące się na
                            zdjęciach lub ilustracjach w dokumencie, które nie są opisane tekstem, nie będą częścią bazy
                            do interakcji z chatbotem. Jeśli potrzebujesz rozwiązania, które będzie obejmowało
                            rozpoznawanie tekstu z obrazów, <Link to="/contact">skontaktuj się z nami</Link>.
                        </>
                    }
                />
                <Expander
                    question="Czy mogę udostępnić stworzonego przez mnie chatbota innym osobom?"
                    answer={
                        <div>
                            <p>
                                Dziś nie jest to jeszcze możliwe, jednak mamy w planach wdrożenie tej funkcjonalności.{" "}
                                <Link to="/contact">Skontaktuj się z nami,</Link> aby skonfigurować twoj.chat dla
                                Twojego zespołu, grupy studentów czy grupy znajomych.
                            </p>
                            <br />
                            <p>Jakie są korzyści z korzystania z narzędzia twoj.chat?</p>
                            <ul>
                                <li>
                                    <b>Wsparcie w podejmowaniu decyzji:</b> Umożliwia błyskawiczne dostarczenie
                                    kluczowych informacji biznesowych, dzięki zdolności do szybkiej analizy dokumentów,
                                    w interakcji z użytkownikiem.
                                </li>
                                <li>
                                    <b>Nauka i rozwijanie kompetencji:</b> Służy jako narzędzie edukacyjne, wspierając
                                    naukę i zdobywanie nowych umiejętności, np. umożliwiając studentom przeszukiwanie
                                    materiałów dydaktycznych.
                                </li>
                                <li>
                                    <b>Usprawnienie obsługi klienta:</b> Poprawia obsługę klienta, dostarczając szybkie
                                    i spersonalizowane odpowiedzi na pytania klientów, może służyć jako wsparcie
                                    pracowników Działów Sprzedaży czy Backoffice.
                                </li>
                            </ul>
                            <p>
                                Masz pomysł jak zastosować czat w swoim biznesie w bardziej zaawansowany sposób?{" "}
                                <Link to="/contact">Skontaktuj się z nami.</Link>
                            </p>
                        </div>
                    }
                />
                <Expander
                    question="Co liczy się jako jeden chatbot w twoj.chat?"
                    answer="Jeden chatbot oznacza chatbota, który zawiera określone dane i może odpowiedzieć na każde pytanie dotyczące tych danych. Nawet jeśli został utworzony przy użyciu kilku dokumentów. Z planem HOBBY i ENTERPRISE możesz utworzyć na jednym koncie więcej niezależnych od siebie chatbotów, bazujących na różnych grupach dokumentów."
                />
                <Expander
                    question="Jak działa plan ENTERPRISE?"
                    answer={
                        <div>
                            <p>W ramach planu ENTERPRISE dostosowujemy nasze rozwiązanie do Twoich potrzeb.</p>
                            <ul>
                                <li>
                                    <b>Duże pliki, obszerna baza:</b> Nasze rozwiązanie pozwala na przekształcenie nawet
                                    obszernej bazy dokumentów w interaktywnego asystenta, umożliwiającego automatyzację
                                    wielu zadań.
                                </li>
                                <li>
                                    <b>Prywatność:</b> Jesteśmy gotowi spełnić potrzebę gwarancji najwyższego poziomu
                                    prywatności dla Twojego chatbota, dbając o bezpieczeństwo nawet najbardziej poufnych
                                    danych, zapewniając pełne zaufanie i ochronę informacji.
                                </li>
                                <li>
                                    <b>Integracje:</b> Dodatkowo, nasza platforma umożliwia łatwą integrację z innymi
                                    narzędziami i systemami, co zwiększa efektywność i elastyczność działania Twojego
                                    chatbota oraz jego zdolność do współpracy z istniejącymi rozwiązaniami w
                                    organizacji.
                                </li>
                            </ul>
                            <p>
                                Chcesz zbudować chat dla swojej organizacji, potrzebujesz prywatnego, wirtualnego
                                asystenta na bazie poufnych danych lub masz pomysł na rozwiązanie, którego jeszcze nie
                                ma na rynku? Skontaktuj się z nami.
                            </p>
                            <br />
                            <p>
                                Jesteśmy doświadczonym zespołem specjalistów oferującym innowacyjne, dostosowane do
                                potrzeb klientów rozwiązania, oparte na dostępnych modelach językowych. Doskonale
                                rozumiemy wyzwania, przed którymi stoją polskie firmy. Opowiedz nam o swoich potrzebach,
                                a my kompleksowo zatroszczymy się o Twój projekt, pomagając Ci osiągnąć sukces.
                            </p>
                        </div>
                    }
                />
                <Expander
                    question="Czy twoj.chat może pomóc w analizie dokumentów biznesowych?"
                    answer={
                        <>
                            Tak, może być wykorzystany do analizy i interakcji z firmowymi dokumentami, np. kodeksem
                            postępowania czy procedurami obsługi klienta. W celu zapewnienia maksymalnego poziomu
                            poufności Twoich dokumentów, zachęcamy do{" "}
                            <Link to="/contact">skontaktowania się z nami,</Link> aby dostosować rozwiązanie do
                            specyficznych potrzeb i przepisów dotyczących ochrony danych w Twojej firmie.
                        </>
                    }
                />
                <Expander
                    question="Czy twoj.chat oferuje możliwość dla różnych formatów dokumentów?"
                    answer={
                        <>
                            Dziś twoj.chat obsługuje tylko pliki PDF. Jeżeli interesuje Cię praca z plikami tekstowymi w
                            innym formacie, <Link to="/contact">skontaktuj się z nami.</Link>
                        </>
                    }
                />
                <Expander
                    question="Jaki jest poziom dostępu i prywatności w twoj.chat?"
                    answer="Twoj.chat umożliwia tworzenie chatbotów, które dostępne są na twoim prywatnym koncie, więc tylko Ty masz dostęp do swojego chatbota."
                />
                <Expander
                    question="Czy moje dane mogą służyć do trenowania modelu?"
                    answer={
                        <>
                            W planie FREE lub HOBBY wprowadzone dane mogą być użyte do trenowania modelu. Pełna poufność
                            danych jest dostępna w planie ENTERPRISE, gdzie Twoje dane przetwarzane są w prywatnej i
                            dedykowanej dla Ciebie infrastrukturze. <Link to="/contact">Skontaktuj się z nami,</Link>{" "}
                            aby omówić oczekiwany poziom prywatności.
                        </>
                    }
                />
                <Expander
                    question="Jak mogę aktualizować dane w moim chatbocie?"
                    answer="W zakładce Baza wiedzy możesz usunąć przesłany plik klikając na ikonkę kosza, a następnie załadować nowy plik."
                />
                <Expander
                    question="Czy twoj.chat obsługuje różne języki?"
                    answer={
                        <>
                            Narzędzie twoj.chat jest skonfigurowane w języku polskim. Jeżeli jednak został przesłany
                            dokument w jezyku innym niż polski, twój chatbot odpowie na twoje zapytanie, jednak jakość
                            odpowiedzi może być niższa. <br />
                            <br /> Jeżeli interesuje Cię interakcja z dokumentami w innym języku za pomocą polskiego
                            narzędzia lub analiza wielojęzycznych tekstów,{" "}
                            <Link to="/contact">zapraszamy do kontaktu</Link> z nami. Nasz zespół przedstawi Ci
                            rozwiązania odpowiadające na twoje potrzeby.
                        </>
                    }
                />
                <Expander
                    question="Czy mogę integrować twoj.chat innymi aplikacjami?"
                    answer={
                        <>
                            Planujemy niebawem wdrożyć taką możliwość. Jeżeli jednak zależy Ci na czasie, masz na myśli
                            konkretne narzędzie, <Link to="/contact">skontaktuj się z nami.</Link>
                        </>
                    }
                />
                <Expander
                    question="Jak szybko twoj.chat odpowiada na pytania?"
                    answer="Skonfigurowany przez Ciebie chatbot natychmiastowo odpowiada na każde pytanie. Zarejestruj się i wypróbuj nasze narzędzie już dziś!"
                />
                <Expander
                    question="Jak mogę śledzić historię rozmów z moim chatbotem?"
                    answer="Twoj.chat już od najniższego planu oferuje funkcję wyświetlania historii rozmów."
                />
                <Expander
                    question="Czy twoj.chat oferuje wsparcie techniczne?"
                    answer={
                        <>
                            Tak, w przypadku pytań lub problemów związanych z narzędziem twoj.chat, możesz skontaktować
                            się z zespołem wsparcia za pośrednictwem <Link to="/contact">formularza.</Link> Odpowiemy
                            najszybciej, jak to będzie możliwe.
                        </>
                    }
                />
            </div>
            <div className={styles.infoSection}>
                <div className={styles.infoTitlesContainer}>
                    <p className={styles.infoTitle}>Nadal masz pytania?</p>
                    <p className={styles.infoSubTitle}>Skontaktuj się z nami</p>
                </div>
                <div className={styles.infoButtonsContainer}>
                    <div className={styles.leftButton}>
                        <Button href={"mailto:kontakt@twoj.chat"} variant="primary" fullWidth>
                            <Email className={styles.buttonIcon} />
                            kontakt@twoj.chat
                        </Button>
                    </div>
                    {/* <div className={styles.rightButton}>
            <Button href={"tel:666222333"} variant="primary" fullWidth>
              <Phone className={styles.buttonIcon} />
              +48 555 222 555
            </Button>
          </div> */}
                </div>
            </div>
        </div>
    );
};
