// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Expander_accordionSummary__ms7ef {
  box-shadow: none !important;
  padding: 0 !important;
}

.Expander_accordionSummary__ms7ef:before {
  height: 0;
}

.Expander_custom-details__eDQ8S {
  padding: 16px;
}

.Expander_icon__MwldC {
  background-color: #0EB77A;
  border-radius: 50%;
  color: #FFFFFF;
  height: 32px;
  width: 32px;
}

.Expander_details__ePzLn {
  font-size: 14px;
  color: #555F6D;
  padding: 0 !important;
}

.Expander_accordion__wi800 {
  padding: 24px;
}
@media (max-width: 1024px) {
  .Expander_accordion__wi800 {
    padding: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Expander/Expander.module.scss","webpack://./src/styles/colors.scss","webpack://./src/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACI,2BAAA;EACA,qBAAA;AAFJ;;AAKA;EACI,SAAA;AAFJ;;AAME;EACE,aAAA;AAHJ;;AAME;EACE,yBCRK;EDSL,kBAAA;EACA,cCpBI;EDqBJ,YAAA;EACA,WAAA;AAHJ;;AAMA;EACE,eAAA;EACA,cCtBU;EDuBV,qBAAA;AAHF;;AAMA;EACE,aAAA;AAHF;AE3BQ;EF6BR;IAGI,aAAA;EADF;AACF","sourcesContent":["@use '../../styles/colors' as colors; \n@use '../../styles/responsive' as rwd;\n\n.accordionSummary {\n    box-shadow: none !important;\n    padding: 0 !important;\n}\n\n.accordionSummary:before {\n    height: 0;\n}\n\n  \n  .custom-details {\n    padding: 16px;\n  }\n  \n  .icon {\n    background-color: colors.$green0;\n    border-radius: 50%;\n    color: colors.$white;\n    height: 32px;\n    width: 32px;\n  }\n\n.details {\n  font-size: 14px;\n  color: colors.$secondary;\n  padding: 0 !important;\n}\n\n.accordion {\n  padding: 24px;\n  @include rwd.breakpoint(small) {\n    padding: 16px;\n  }\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;","@mixin breakpoint($point) {\n    @if $point == small {\n        @media (max-width:  1024px) { @content; }\n    }\n    // @else if $point == medium {\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\n    // }\n    @else if $point == large {\n        @media (min-width:  1025px) { @content; }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionSummary": `Expander_accordionSummary__ms7ef`,
	"custom-details": `Expander_custom-details__eDQ8S`,
	"icon": `Expander_icon__MwldC`,
	"details": `Expander_details__ePzLn`,
	"accordion": `Expander_accordion__wi800`
};
export default ___CSS_LOADER_EXPORT___;
