import React, { useState } from "react";

import { Link } from "react-scroll";

import { Card } from "../../Card/Card";

import { SectionHeader } from "../../SectionHeader/SectionHeader";

import { ReactComponent as Arrow } from "../../../assets/icons/rightArrowBordered.svg";
import { Button } from "../../Button/Button";

import styles from "./SecondSection.module.scss";

export const SecondSection = () => {
    const [tabValue, setTabValue] = useState<number>(1);

    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
    };
    return (
        <div className={styles.wrapper} id="howItWorks">
            <div className={styles.leftWrapper}>
                <SectionHeader title="To proste i bardzo skuteczne narzędzie" badge={{ text: "Jak to działa?" }} />
                <div className={styles.stepsContainer}>
                    <div
                        className={tabValue === 1 ? styles.stepContainerChecked : styles.stepContainer}
                        onClick={() => handleTabChange(1)}
                        data-testid="stepContainer1"
                    >
                        <span className={styles.number}>1</span>
                        Dodaj dokumenty
                        {tabValue === 1 && <Arrow className={styles.icon} />}
                    </div>
                    <div className={styles.mobileWrapper}>
                        {tabValue === 1 && (
                            <Card
                                title="Załaduj dokumenty i aktywuj swojego chatbota"
                                subTitle="Wystarczy, że wgrasz swoje dokumenty, a my przekształcimy ich treść w
          wiedzę Twojego chatbota. Stworzenie chatbota jest takie proste."
                            />
                        )}
                        {tabValue === 2 && (
                            <Card
                                title="Zadawaj pytania swojemu chatbotowi w języku naturalnym"
                                subTitle="Dokładne odpowiedzi na podstawie wgranych dokumentów. Żadnych przypadkowych odpowiedzi. Jeżeli odpowiedzi nie ma w przesłanych 
            dokumentach, chatbot powie „nie wiem”. Oparty o GPT-3.5 / GPT-4"
                            />
                        )}
                        {tabValue === 3 && (
                            <Card
                                title="Podziel się swoim chatbotem z innymi!"
                                subTitle="Udostępnij link do swojego chatbota, aby inni mogli z nim rozmawiać. Każdy, kto posiada link, może zadawać pytania Twojemu chatbotowi. Możesz też zachować chatbota tylko dla siebie i cieszyć się pełną prywatnością."
                            />
                        )}
                    </div>
                    <div
                        className={tabValue === 2 ? styles.stepContainerChecked : styles.stepContainer}
                        onClick={() => handleTabChange(2)}
                        data-testid="stepContainer2"
                    >
                        <span className={styles.number}>2</span>
                        Zadaj pytanie swojemu chatowi
                        {tabValue === 2 && <Arrow className={styles.icon} />}
                    </div>
                    <div
                        className={tabValue === 3 ? styles.stepContainerChecked : styles.stepContainer}
                        onClick={() => handleTabChange(3)}
                        data-testid="stepContainer3"
                    >
                        <span className={styles.number}>3</span>
                        Podziel się swoim chatbotem z innymi
                        {tabValue === 3 && <Arrow className={styles.icon} />}
                    </div>
                    <div className={styles.mobileWrapperButton}>
                        <Link to="prices" spy smooth offset={-50} duration={500} className={styles.singleLink}>
                            <Button size="large" variant="secondary" fullWidth>
                                Stwórz chatbota
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {tabValue === 1 && (
                    <Card
                        title="Załaduj dokumenty i aktywuj swojego chatbota"
                        subTitle="Wystarczy, że wgrasz swoje dokumenty, a my przekształcimy ich treść w
          wiedzę Twojego chatbota. Stworzenie chatbota jest takie proste."
                        actionButton={{ text: "Stwórz chatbota", href: "prices" }}
                        dataTestid="desktopCard1"
                    />
                )}
                {tabValue === 2 && (
                    <Card
                        title="Zadawaj pytania swojemu chatbotowi w języku naturalnym"
                        subTitle="Dokładne odpowiedzi na podstawie wgranych dokumentów. Żadnych przypadkowych odpowiedzi. Jeżeli odpowiedzi nie ma w przesłanych 
            dokumentach, chatbot powie „nie wiem”. Oparty o GPT-3.5 / GPT-4"
                        actionButton={{ text: "Stwórz chatbota", href: "prices" }}
                        dataTestid="desktopCard2"
                    />
                )}
                {tabValue === 3 && (
                    <Card
                        title="Podziel się swoim chatbotem z innymi!"
                        subTitle="Udostępnij link do swojego chatbota, aby inni mogli z nim rozmawiać. Każdy, kto posiada link, może zadawać pytania Twojemu chatbotowi. Możesz też zachować chatbota tylko dla siebie i cieszyć się pełną prywatnością."
                        actionButton={{ text: "Stwórz chatbota", href: "prices" }}
                        dataTestid="desktopCard3"
                    />
                )}
            </div>
        </div>
    );
};
