// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThirdSection_wrapper__Ermh1 {
  padding: 120px 80px;
}
@media (max-width: 1024px) {
  .ThirdSection_wrapper__Ermh1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 44px 0px;
  }
}

@media (max-width: 1024px) {
  .ThirdSection_sectionHeaderContainer__PIM-k {
    text-align: center;
  }
}

.ThirdSection_cardsContainer__XvSab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-top: 64px;
  margin-bottom: 64px;
}
@media (max-width: 1024px) {
  .ThirdSection_cardsContainer__XvSab {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.ThirdSection_buttonsContainer__DEzjZ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  width: 100%;
}
@media (max-width: 1024px) {
  .ThirdSection_buttonsContainer__DEzjZ {
    gap: 8px;
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sections/ThirdSection/ThirdSection.module.scss","webpack://./src/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACI,mBAAA;AAFJ;ACAQ;EDCR;IAGQ,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,uBAAA;IACA,iBAAA;EAAN;AACF;;ACTQ;EDYR;IAEQ,kBAAA;EAAN;AACF;;AAGA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;ACvBQ;EDkBR;IAQQ,aAAA;IACA,sBAAA;IACA,SAAA;IACA,gBAAA;IACA,mBAAA;EACN;AACF;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;AACJ;ACxCQ;EDkCR;IAOQ,QAAA;IACA,sBAAA;EAGN;AACF","sourcesContent":["@use '../../../styles/colors' as colors; \n@use '../../../styles/responsive' as rwd; \n\n.wrapper {\n    padding: 120px 80px;\n    @include rwd.breakpoint(small) {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        padding: 44px 0px;\n    }\n}\n\n.sectionHeaderContainer {\n    @include rwd.breakpoint(small) {\n        text-align: center;\n    }\n}\n\n.cardsContainer {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 32px;\n    margin-top: 64px;\n    margin-bottom: 64px;\n    \n    @include rwd.breakpoint(small) {\n        display: flex;\n        flex-direction: column;\n        gap: 16px;\n        margin-top: 32px;\n        margin-bottom: 32px;\n    }\n}\n\n.buttonsContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 16px;\n    width: 100%;\n    @include rwd.breakpoint(small) {\n        gap: 8px;\n        flex-direction: column;\n    }\n}","@mixin breakpoint($point) {\n    @if $point == small {\n        @media (max-width:  1024px) { @content; }\n    }\n    // @else if $point == medium {\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\n    // }\n    @else if $point == large {\n        @media (min-width:  1025px) { @content; }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ThirdSection_wrapper__Ermh1`,
	"sectionHeaderContainer": `ThirdSection_sectionHeaderContainer__PIM-k`,
	"cardsContainer": `ThirdSection_cardsContainer__XvSab`,
	"buttonsContainer": `ThirdSection_buttonsContainer__DEzjZ`
};
export default ___CSS_LOADER_EXPORT___;
