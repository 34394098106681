import axios, { AxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";

import { URLS } from "../api/urls";
import { UserDetails } from "../api/types";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
    headers: {
        Authorization?: string;
    };
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

api.interceptors.request.use(
    async (config: any) => {
        const newConfig: InternalAxiosRequestConfig = { ...config };

        const user = JSON.parse(localStorage.getItem("user") ?? "{}") as UserDetails;

        if (user.access_token && user.access_token !== "undefined") {
            const decodedToken: { exp: number } = await jwt_decode(user.access_token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp - currentTime <= 600) {
                try {
                    const refreshToken = user.refresh_token;
                    const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}${URLS.REFRESH_TOKEN}`, {
                        refresh_token: refreshToken,
                    });
                    const newUserData: UserDetails = {
                        ...user,
                        access_token: response.data.access_token,
                        refresh_token: response.data.refresh_token,
                    };
                    localStorage.setItem("user", JSON.stringify(newUserData));
                    newConfig.headers.Authorization = `Bearer ${response.data.access_token}`;
                } catch (error) {
                    console.log(error);
                    localStorage.removeItem("user");
                    throw error;
                }
            } else {
                config.headers.Authorization = `Bearer ${user.access_token}`;
                return config;
            }
        }

        return newConfig;
    },
    (error) => Promise.reject(error),
);

export default api;
